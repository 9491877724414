import React, { useState } from "react";
import { Tabs, Tab, Box, Grid } from "@mui/material";
import Customer from "../../pages/Money/Credit/customer";
import Staff from "../../pages/Money/Credit/staff";
import Daily from "../../pages/Money/Credit/daily";
import useDocumentById from "../../hooks/useDocumentById";
import ShowBudget from "../Budget/ShowBudget";
import generateCustomID from "../../utils/generateCustomID";

const MyTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const storedData = localStorage.getItem("userData");

  let branchId = "";
  if (storedData) {
    const userData = JSON.parse(storedData);
    branchId = userData ? userData.id : "try";
  }

  const customerCreditTotalId = generateCustomID(`${branchId}-customer`);
  const staffCreditTotalId = generateCustomID(`${branchId}-staff`);
  const dailyCreditTotalId = generateCustomID(`${branchId}-daily`);

  console.log(customerCreditTotalId, staffCreditTotalId, dailyCreditTotalId);

  const { documentData: totalCustomerCredit } = useDocumentById(
    "totalCredit",
    customerCreditTotalId
  );

  const { documentData: totalStaffCredit } = useDocumentById(
    "totalCredit",
    staffCreditTotalId
  );

  const { documentData: totalDailyCredit } = useDocumentById(
    "totalCredit",
    dailyCreditTotalId
  );

  const tabData = {
    2: {
      variableValue: totalDailyCredit?.dailyCreditTotal
        ? totalDailyCredit?.dailyCreditTotal
        : 0,
      variableName: "Total Daily Credit",
    },
    1: {
      variableValue: totalStaffCredit?.staffCreditTotal
        ? totalStaffCredit?.staffCreditTotal
        : 0,
      variableName: "Total Staff Credit",
    },
    0: {
      variableValue: totalCustomerCredit?.customerCreditTotal
        ? totalCustomerCredit?.customerCreditTotal
        : 0,
      variableName: "Total Customer Credit",
    },
  };
  console.log(totalCustomerCredit, totalStaffCredit, totalDailyCredit);
  console.log(tabData);

  const selectedTabData = tabData[selectedTab];

  return (
    <Box m="1rem 0">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            // variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
          >
            <Tab label="Customer" />
            <Tab label="Staff" />
            <Tab label="Daily Credit" />
          </Tabs>
        </Grid>
        <Grid item xs={6}>
          <ShowBudget
            label={selectedTabData?.variableName}
            value={selectedTabData?.variableValue}
            marginTop={10}
          />
        </Grid>
      </Grid>

      {selectedTab === 0 && <Customer />}
      {selectedTab === 1 && (
        <Staff
          StaffCredit={
            totalCustomerCredit?.customerCreditTotal
              ? totalCustomerCredit?.customerCreditTotal
              : 0
          }
        />
      )}
      {selectedTab === 2 && <Daily />}
    </Box>
  );
};

export default MyTabs;
