const determineCollectionName = (customClaims) => {
  let collectionName = null; // Default value if no matching role is found

  if (customClaims.superAdmin) {
    collectionName = "Superadmin";
  } else if (customClaims.admin) {
    collectionName = "admin";
  } else if (customClaims.callCenter) {
    collectionName = "callcenter";
  } else if (customClaims.finance) {
    collectionName = "finance";
  }

  return collectionName;
};

export default determineCollectionName;
