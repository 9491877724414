import DynamicTable from "../DynamicTable/DynamicTable";
import { useState } from "react";
import { Box } from "@mui/material";
import { firestore } from "../../services/firebase";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { useEffect } from "react";
import { useCallback } from "react";
import getRequiredUserData from "../../utils/getBranchInfo";
import generateCustomID from "../../utils/generateCustomID";
import useDocumentById from "../../hooks/useDocumentById";

const columns = [
  { key: "no", title: "No" },
  { key: "name", title: "Name" },
  { key: "amount", title: "Amount" },
];

const NewExpenseTable = ({ id, totalDeliveryGuySalary, totalStaffSalary }) => {
  const [statusData, setStatusData] = useState({});
  const [salary, setSalary] = useState({});
  const [staffSalary, setStaffSalary] = useState({});

  let statusDataArray = [];
  const branchData = getRequiredUserData();

  const customerCreditTotalId = generateCustomID(
    `${branchData.requiredId}-customer`
  );
  const staffCreditTotalId = generateCustomID(`${branchData.requiredId}-staff`);
  const dailyCreditTotalId = generateCustomID(`${branchData.requiredId}-daily`);

  const { documentData: totalCustomerCredit } = useDocumentById(
    "totalCredit",
    customerCreditTotalId
  );

  const { documentData: totalStaffCredit } = useDocumentById(
    "totalCredit",
    staffCreditTotalId
  );

  const { documentData: totalDailyCredit } = useDocumentById(
    "totalCredit",
    dailyCreditTotalId
  );

  useEffect(() => {
    if (!id) {
      return;
    }
    const worksRef = doc(collection(firestore, "Status"), id);

    const unsubscribe = onSnapshot(worksRef, (doc) => {
      if (doc.exists()) {
        setStatusData({
          ...doc.data(),
        });
      }
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const worksRef = doc(collection(firestore, "salary"), id);

    const unsubscribe = onSnapshot(worksRef, (doc) => {
      if (doc.exists()) {
        setSalary({
          ...doc.data(),
        });
      }
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    if (!id) {
      return;
    }
    const worksRef = doc(collection(firestore, "staffSalary"), id);

    const unsubscribe = onSnapshot(worksRef, (doc) => {
      if (doc.exists()) {
        setStaffSalary({
          ...doc.data(),
        });
      }
    });

    return () => unsubscribe();
  }, [id]);

  // useEffect(() => {
  //   if (!branchData.requiredId) {
  //     return;
  //   }
  //   const worksRef = doc(
  //     collection(firestore, "totalCredit"),
  //     branchData.requiredId
  //   );

  //   const unsubscribe = onSnapshot(worksRef, (doc) => {
  //     if (doc.exists()) {
  //       setCredit({
  //         ...doc.data(),
  //       });
  //     }
  //   });

  //   return () => unsubscribe();
  // }, [branchData.requiredId]);

  const excludeProperties = [
    "houseRentOwnerName",
    "wifiAccount",
    "wifiOwnerName",
    "houseRentAccount",
    "houseRentOwnerName",
    "ethioTelAccount",
    "ethioTelOwnerName",
    "taxPersentage",
    "date",
    "createdDate",
    "branchId",
  ]; // Add the properties you want to exclude here

  // Define the desired order of keys (ExpenseName)
  const orderOfKeys = [
    "wifi",
    "houseRent",
    "totaltax",
    "ethioTelBill",
    "totalStaffSalary",
    "totalDeliveryGuySalary",
    "totalIncome",
    "totalExpense",
  ];

  if (statusData) {
    // Find properties that are not in excludeProperties or orderOfKeys
    const additionalProperties = Object.keys(statusData).filter(
      (key) => !excludeProperties.includes(key) && !orderOfKeys.includes(key)
    );

    // Combine additionalProperties and ordered properties
    statusDataArray = [
      ...additionalProperties.map((key, index) => ({
        no: index + 1,
        name: key,
        amount: statusData[key],
      })),
      ...orderOfKeys
        .filter((key) => !excludeProperties.includes(key))
        .map((key, index) => ({
          no: index + additionalProperties.length + 1,
          name: key,
          amount: statusData[key],
        })),
    ];
    statusDataArray.push({
      no: statusDataArray.length + 1,
      name: "totalCredit",
      amount: (
        (totalCustomerCredit?.customerCreditTotal || 0) +
        (totalStaffCredit?.staffCreditTotal || 0) +
        (totalDailyCredit?.dailyCreditTotal || 0)
      ).toFixed(2),
    });

    statusDataArray = statusDataArray.map((item) => {
      if (item.name === "totalDeliveryGuySalary") {
        item.amount -= parseInt(salary?.total?.totalCredit) || 0;
      }
      if (item.name === "totalStaffSalary") {
        item.amount -= parseInt(staffSalary?.total?.totalCredit) || 0;
      }
      return item;
    });
  }

  const loadMoreData = useCallback(async () => {
    return null;
  }, []);
  useEffect(() => {
    const handleDynamicTableScroll = (event) => {
      const scrollPosition = event.detail.scrollPosition;
    };

    window.addEventListener("dynamicTableScroll", handleDynamicTableScroll);

    return () => {
      window.removeEventListener(
        "dynamicTableScroll",
        handleDynamicTableScroll
      );
    };
  }, []);

  return (
    <Box m="1rem 0">
      <DynamicTable
        data={Object.keys(statusData).length !== 0 ? statusDataArray : []}
        columns={columns}
        loadMoreData={loadMoreData}
        containerHeight={480}
      />
    </Box>
  );
};

export default NewExpenseTable;
