import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../services/firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
  confirmPasswordReset,
} from "firebase/auth";

import determineCollectionName from "../utils/determineCollectionName";
import useUserClaims from "../hooks/useUserClaims";
import useDocumentById from "../hooks/useDocumentById";

const AuthContext = createContext({
  currentUser: null,
  user: null,
  validatingUser: true,
  signInWithGoogle: () => Promise,
  login: () => Promise,
  register: () => Promise,
  logout: () => Promise,
  forgotPassword: () => Promise,
  resetPassword: () => Promise,
});

export const useAuth = () => useContext(AuthContext);

export default function AuthContextProvider({ children }) {
  const [user, setUser] = useState(false);
  const [validatingUser, setValidatingUser] = useState(true);
  const customClaims = useUserClaims(user);
  const collectionName = determineCollectionName(customClaims);
  const { documentData: currentUser } = useDocumentById(
    collectionName,
    user.uid
  );

  useEffect(() => {
    const unSubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        // getCurrentUserDetails(user);
      } else {
        setUser(false);
      }
      setValidatingUser(false);
    });

    return () => unSubscribe();
  }, []);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function register(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function forgotPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      // url: `http://localhost:3000/login`,
      url: `https://login.ashamlolie.com/login`,
    });
  }

  function resetPassword(oobCode, newPassword) {
    return confirmPasswordReset(auth, oobCode, newPassword);
  }

  function logout() {
    return signOut(auth);
  }

  function signInWithGoogle() {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider);
  }

  const value = {
    user,
    validatingUser,
    currentUser,
    signInWithGoogle,
    login,
    register,
    logout,
    forgotPassword,
    resetPassword,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
